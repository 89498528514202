// src/components/CreateCollection.js
import React from 'react';
import { Form, Input, Button, FlexDiv } from '../StyledComponents';
import styled from 'styled-components';

const ErrorText = styled.p`
  color: red;
`;

const CheckboxLabel = styled.label`
  margin: 10px 0;
`;

function CreateCollection({
  newCollectionName,
  setNewCollectionName,
  customLink,
  setCustomLink,
  customLinkError,
  setExpiryDate,
  visibility,
  setVisibility,
  handleCreateCollection
}) {
  return (
    <div>
      <h2>Create a New Collection</h2>
      <Form onSubmit={handleCreateCollection}>
        <Input
          type="text"
          placeholder="Collection Name"
          value={newCollectionName}
          onChange={(e) => setNewCollectionName(e.target.value)}
          required
        />
        <Input
          type="text"
          placeholder="Custom Link"
          value={customLink}
          onChange={(e) => setCustomLink(e.target.value)}
        />
        {customLinkError && <ErrorText>{customLinkError}</ErrorText>}
        <FlexDiv>
          <p style={{ margin: 0 }}>Expiry date:</p>
          <Input
            type="datetime-local"
            value={null}
            onChange={(e) => setExpiryDate(new Date(e.target.value))}
          />
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={visibility}
              onChange={(e) => setVisibility(e.target.checked)}
            />
            PUBLIC?
          </CheckboxLabel>
        </FlexDiv>
        <Button type="submit">Create</Button>
      </Form>
    </div>
  );
}

export default CreateCollection;
