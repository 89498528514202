import React, { useState, useEffect } from 'react';
import { faCog, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    SettingsButton, ImageGrid, Image, Button, Checkbox, CenteredColumnDiv, FlexDiv, GoogleButton, CollectionPhotosContainer, BlueButton, Modal, ModalContent, CloseButton, PromptFlexDiv, PromptContainer, ImagePreview
} from '../StyledComponents';
import CollectionSettingsModal from './CollectionSettingsModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useAlert } from './AlertContext';

function CollectionPhotos() {
    const location = useLocation();
    const selectedCollectionId = location?.state?.selectedCollectionId;
    const [loadedSelectedCollection, setLoadedSelectedCollection] = useState(null);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [authError, setAuthError] = useState(null);
    const [prevPhotoCount, setPrevPhotoCount] = useState(0);
    const [checkMode, setCheckMode] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState({});
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const navigate = useNavigate();
    const showAlert = useAlert();

    useEffect(() => {
        if (authError === true) {
            navigate('/not-found', { state: { message: 'You are not authorized to view this collection.' } });
        }
    }, [authError]);

    useEffect(() => {
        if (!selectedCollectionId) {
            setLoadedSelectedCollection(null);
            setPrevPhotoCount(0);
            return;
        }

        const fetchPhotos = async () => {
            try {
                const response = await fetch(`https://backend.howwasit.markoshub.com:3333/api/photos/${selectedCollectionId}`);
                const data = await response.json();
                setLoadedSelectedCollection({
                    id: selectedCollectionId,
                    photos: data.fileURLs.map(url => ({ url }))
                });

                const newPhotosCount = data.fileURLs.length;
                if (newPhotosCount > prevPhotoCount) {
                    console.log('New photos added:', newPhotosCount);
                    showAlert(`${newPhotosCount} new photo(s) added. Click OK to refresh.`);
                }
                setPrevPhotoCount(newPhotosCount);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        fetchPhotos();
    }, [selectedCollectionId]);

    useEffect(() => {
        if (deleteConfirmed) {
            deleteSelectedPhotos();
            setDeleteConfirmed(false);
        }
    }, [deleteConfirmed]);

    const handleSettingsClick = () => {
        setShowSettingsModal(true);
    };

    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false);
    };

    const openImage = (url) => {
        setSelectedImageUrl(url);
        setShowImageModal(true);
    };

    const handleCloseImageModal = () => {
        setShowImageModal(false);
        setSelectedImageUrl(null);
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseImageModal();
        }
    };

    const getFileExtensionFromMimeType = (mimeType) => {
        switch (mimeType) {
            case 'image/jpeg':
                return 'jpg';
            case 'image/png':
                return 'png';
            default:
                return '';
        }
    };

    const downloadFolderAsZip = async () => {
        try {
            if (!selectedCollectionId || typeof selectedCollectionId !== 'string') {
                throw new Error('Invalid collection ID');
            }

            const jszip = new JSZip();
            const response = await fetch(`https://backend.howwasit.markoshub.com:3333/api/photos/${selectedCollectionId}`);
            const data = await response.json();

            const promises = data.fileURLs.map(async (url) => {
                try {
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch ${url}`);
                    }

                    const fileBlob = await response.blob();
                    const fileExtension = getFileExtensionFromMimeType(fileBlob.type);
                    const fileName = url.split('/').pop();
                    jszip.file(`${fileName}.${fileExtension}`, fileBlob);
                } catch (error) {
                    console.error('Error fetching or adding file:', url, error);
                }
            });

            await Promise.all(promises);

            const blob = await jszip.generateAsync({ type: 'blob' });
            saveAs(blob, 'download.zip');
        } catch (error) {
            console.error('Error downloading folder as zip:', error);
        }
    };

    const toggleCheckMode = () => {
        setCheckMode(!checkMode);
        if (!checkMode) {
            setSelectedPhotos({});
        }
    };

    const togglePhotoSelection = (url) => {
        setSelectedPhotos(prevState => ({
            ...prevState,
            [url]: prevState[url] === undefined ? true : !prevState[url],
        }));
    };

    const deleteSelectedPhotos = async () => {
        try {
            const photosToDelete = loadedSelectedCollection.photos.filter(photo => selectedPhotos[photo.url]);

            const deletePromises = photosToDelete.map(async (photo) => {
                try {
                    const urlParts = photo.url.split('/');
                    const collectionId = urlParts[urlParts.length - 2];
                    const filename = urlParts[urlParts.length - 1];

                    const response = await fetch(`https://backend.howwasit.markoshub.com:3333/api/uploads/${collectionId}/${filename}`, { method: 'DELETE' });
                    if (!response.ok) {
                        throw new Error(`Failed to delete ${photo.url}`);
                    }
                } catch (error) {
                    console.error(`Error deleting ${photo.url}:`, error);
                    throw error; // Rethrow the error to handle it outside
                }
            });

            await Promise.all(deletePromises);

            // After successful deletion, update the frontend state to remove deleted photos
            const updatedPhotos = loadedSelectedCollection.photos.filter(photo => !selectedPhotos[photo.url]);
            setLoadedSelectedCollection(prevState => ({
                ...prevState,
                photos: updatedPhotos
            }));

            // Clear selected photos state
            setSelectedPhotos({});
            showAlert(`Successfully deleted ${photosToDelete.length} photos.`);
        } catch (error) {
            console.error('Error deleting photos:', error);
        }
    };


    const invertSelection = () => {
        const invertedSelections = {};
        loadedSelectedCollection.photos.forEach(photo => {
            invertedSelections[photo.url] = !selectedPhotos[photo.url];
        });
        setSelectedPhotos(invertedSelections);
    };

    const handleDeleteClick = () => {
        setShowConfirmationDialog(true);
    };

    const handleConfirmDelete = () => {
        setDeleteConfirmed(true);
        setShowConfirmationDialog(false);
    };

    const handleCancelDelete = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <CollectionPhotosContainer>
            <Button onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>
            <SettingsButton icon={faCog} onClick={handleSettingsClick} />
            <ImageGrid>
                {loadedSelectedCollection?.photos.length > 0 ? (
                    loadedSelectedCollection.photos.map((photo) => (
                        <CenteredColumnDiv key={photo.url}>
                            {checkMode && (
                                <Checkbox
                                    type="checkbox"
                                    checked={!!selectedPhotos[photo.url]}
                                    onChange={() => togglePhotoSelection(photo.url)}
                                />
                            )}
                            <Image src={photo.url} onClick={() => openImage(photo.url)} />
                        </CenteredColumnDiv>
                    ))
                ) : (
                    <CenteredColumnDiv>No photos added yet</CenteredColumnDiv>
                )}
            </ImageGrid>
            <FlexDiv>
                {!checkMode ? (
                    <Button onClick={toggleCheckMode}>Enable Check Mode</Button>
                ) : (
                    <div>
                        <Button onClick={toggleCheckMode}>Disable Check Mode</Button>
                        <BlueButton onClick={invertSelection}>Invert Selection</BlueButton>
                        <GoogleButton onClick={handleDeleteClick}>Delete Selected Photos</GoogleButton>
                    </div>
                )}
                <Button onClick={downloadFolderAsZip}>Download All Photos</Button>
            </FlexDiv>
            <SettingsButton onClick={() => setShowSettingsModal(true)}>Settings</SettingsButton>
            {showSettingsModal && <CollectionSettingsModal show={showSettingsModal}
                handleClose={handleCloseSettingsModal}
                selectedCollection={loadedSelectedCollection} />}
            {showImageModal && (
                <Modal onClick={handleBackgroundClick}>
                    <ModalContent>
                        <CloseButton onClick={handleCloseImageModal}>
                            <FontAwesomeIcon icon={faTimes} />
                        </CloseButton>
                        <ImagePreview src={selectedImageUrl} alt="Selected" style={{ width: '100%', height: 'auto' }} />
                    </ModalContent>
                </Modal>
            )}
            {showConfirmationDialog && (
                <Modal onClick={handleCancelDelete}>
                    <ModalContent>
                        <PromptContainer>
                            <CenteredColumnDiv>
                                <h3>Confirm Deletion</h3>
                                <CloseButton onClick={handleCancelDelete}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </CloseButton>
                            </CenteredColumnDiv>
                            <p>Are you sure you want to delete the selected photos?</p>
                            <FlexDiv>
                                <GoogleButton onClick={handleConfirmDelete} style={{ marginRight: '1rem' }}>
                                    Yes, Delete
                                </GoogleButton>
                                <Button onClick={handleCancelDelete}>Cancel</Button>
                            </FlexDiv>
                        </PromptContainer>
                    </ModalContent>
                </Modal>
            )}
        </CollectionPhotosContainer>
    );
}

export default CollectionPhotos;
