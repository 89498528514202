import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { storage, db, auth } from '../firebase';
import { getDocs } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { addDoc, collection, getDoc, doc } from 'firebase/firestore';
import { v4 as UUID } from 'uuid';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PageContainer, Form, FileInput, ProgressBar, ImgPreview, GalleryContainer, Button, Spinner, SpinnerWrapper, FlexDiv, CenteredColumnDiv, ImageGrid } from '../StyledComponents';
import { useAlert } from './AlertContext';
import axios from 'axios';

function UploadPhoto() {
  const { collectionId } = useParams();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);
  const [collectionName, setCollectionName] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [owner, setOwner] = useState(false);
  const [collectionExists, setCollectionExists] = useState(null);
  const [collectionVisibility, setCollectionVisibility] = useState(null);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const showAlert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  function calculateTimeLeft() {
    const difference = +new Date(expiryDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const goBackToDashboard = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    console.log('Collection ID:', collectionId);
    // Fetch collection details from your backend here
  }, [collectionId]);

  useEffect(() => {
    if (collectionExists === false || collectionVisibility === false) {
      navigate('/not-found');
    }
  }, [collectionExists, navigate, collectionVisibility]);

  useEffect(() => {
    console.log('Collection ID:', collectionId);
    const fetchCollection = async () => {
      const collectionRef = doc(db, 'collections', collectionId);
      const collectionSnapshot = await getDoc(collectionRef);
      if (collectionSnapshot.exists()) {
        const collectionData = collectionSnapshot.data();
        setCollectionName(collectionData.name);
        console.log('Expires At:', collectionData.expiresAt);
        setExpiryDate(new Date(collectionData.expiresAt));
        setCollectionExists(true);
        setCollectionVisibility(collectionData.visibility);
        if (auth.currentUser !== null) {
          if (auth.currentUser.uid === collectionData.createdBy) {
            setOwner(true);
            const photosRef = collection(db, 'collections', collectionId, 'photos');
            const photosSnapshot = await getDocs(photosRef);
            const newPhotos = [];
            photosSnapshot.forEach((doc) => {
              newPhotos.push(doc.data());
            });
            setPhotos(newPhotos);
          }
        }
      } else {
        setCollectionExists(false);
      }
    };
    fetchCollection();
  }, [collectionId]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      showAlert('Please select at least one file to upload.');
      return;
    }

    const uploadPromises = files.map((file, index) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);

        const config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[index] = progress;
              return newProgress;
            });
          }
        };

        axios.post(`https://backend.howwasit.markoshub.com:3333/api/upload/${collectionId}`, formData, config)
          .then(response => {
            const url = URL.createObjectURL(file);
            setPreviewURLs((prevURLs) => [...prevURLs, url]);
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
            showAlert('File upload failed.');
            reject(error);
          });
      });
    });

    try {
      await Promise.all(uploadPromises);
      setFiles([]);
      document.getElementById('fileInput').value = '';
      showAlert('Files uploaded successfully.');
    } catch (error) {
      console.error('Error uploading files:', error);
      showAlert('Some files failed to upload.');
    }
  };

  if (collectionExists === null) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <PageContainer>
      <FlexDiv>
        <h1>{collectionName}</h1>
        {auth.currentUser && <Button onClick={goBackToDashboard}>Back to Dashboard</Button>}
        <b>{timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s left</b>
      </FlexDiv>
      <CenteredColumnDiv>
        <Form onSubmit={handleUpload}>
          <FileInput id="fileInput" type="file" multiple onChange={(e) => setFiles(Array.from(e.target.files))} />
          <Button type="submit">Upload Photos</Button>
        </Form>
        {files.map((file, index) => (
          <div key={index}>
            <p>{file.name}</p>
            <ProgressBar value={uploadProgress[index] || 0} max="100">{uploadProgress[index] || 0}%</ProgressBar>
          </div>
        ))}
      </CenteredColumnDiv>
      <ImageGrid>
        {previewURLs.map((url, index) => (
          <CenteredColumnDiv key={index}>
            <ImgPreview src={url} alt="Preview" />
          </CenteredColumnDiv>
        ))}
      </ImageGrid>
    </PageContainer>
  );
}

export default UploadPhoto;
