// src/components/CollectionSettingsModal.js
import React, { useState, useEffect } from 'react';
import { ModalOverlay, ModalContainer, ModalHeader, ModalTitle, ModalCloseButton, ModalBody, ModalFooter, Button, Input, RadioGroup, RadioLabel, RadioInput, FlexDiv } from '../StyledComponents';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

function CollectionSettingsModal({ show, handleClose, selectedCollection }) {
    const [expiresAt, setExpiresAt] = useState(selectedCollection.expiresAt);
    const [visibility, setVisibility] = useState(selectedCollection.visibility);

    useEffect(() => {
        setExpiresAt(selectedCollection.expiresAt);
    }, [selectedCollection.expiresAt]);

    useEffect(() => {
        setVisibility(selectedCollection.visibility);
    }, [selectedCollection.visibility]);

    const handleSaveChanges = async () => {
        try {
            const collectionRef = doc(db, 'collections', selectedCollection.id);
            await updateDoc(collectionRef, {
                ...selectedCollection,
                expiresAt: expiresAt ? new Date(expiresAt).getTime() : null,
                visibility,
            });
        } catch (error) {
            alert('Failed to update collection.');
        }
    };

    if (!show) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>
                    <ModalTitle>Edit Collection Settings</ModalTitle>
                    <ModalCloseButton icon={faXmark} onClick={handleClose}></ModalCloseButton>
                </ModalHeader>
                <ModalBody>
                    <FlexDiv>
                    <label>Expires at </label>
                    <Input
                        type="datetime-local"
                        value={expiresAt ? new Date(new Date(expiresAt).getTime() + 60 * 60 * 1000).toISOString().slice(0, 16) : ''} onChange={(e) => setExpiresAt(new Date(e.target.value))}
                        />
                    </FlexDiv>
                    <FlexDiv>
                        <label>Visibility</label>
                        <RadioGroup>
                            <RadioLabel>
                                <RadioInput
                                    type="radio"
                                    checked={visibility === true}
                                    onChange={() => setVisibility(true)}
                                />
                                Public
                            </RadioLabel>
                            <RadioLabel>
                                <RadioInput
                                    type="radio"
                                    checked={visibility === false}
                                    onChange={() => setVisibility(false)}
                                />
                                Private
                            </RadioLabel>
                        </RadioGroup>
                    </FlexDiv>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleClose} style={{ marginRight: '10px' }}>Close</Button>
                    <Button onClick={handleSaveChanges}>Save Changes</Button>
                </ModalFooter>
            </ModalContainer>
        </ModalOverlay>
    );
}

export default CollectionSettingsModal;
