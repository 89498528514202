// src/components/CollectionsList.js
import React, { useEffect, useState } from 'react';
import { onSnapshot, collection } from 'firebase/firestore';
import { db, auth} from '../firebase';
import { ListContainer, CollectionList, CollectionItem, Spinner, SpinnerWrapper } from '../StyledComponents';

function CollectionsList({ handleCollectionClick, loading, error, selectedCollectionId }) {
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'collections'), (snapshot) => {
          const updatedCollections = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((collection) => collection.createdBy === auth.currentUser?.uid); // Filter collections by current user's UID
          setCollections(updatedCollections);
        });
    
        return () => unsubscribe();
      }, []);

    return (
        <ListContainer>
            <h2>Your Collections</h2>
            {loading && <SpinnerWrapper>
                <Spinner />
            </SpinnerWrapper>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                {collections.length > 0 ? (
                    <CollectionList>
                        {collections.map((collection) => (
                            <CollectionItem
                                key={collection.id}
                                onClick={() => handleCollectionClick(collection)}
                                className={`${selectedCollectionId === collection.id ? 'selected' : ''}`}
                            >
                                {collection.name}
                            </CollectionItem>
                        ))}
                    </CollectionList>
                ) : (
                    <p>No collections found.</p>
                )}
            </div>
        </ListContainer>
    );
}

export default CollectionsList;
