import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { HeaderContainer, FlexDiv, Button, HeaderLogo, HeaderAbsoluteButton } from '../StyledComponents';

function Header() {
  const navigate = useNavigate();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(() => {
    console.log('Setting up auth state change listener');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user);
      setIsUserSignedIn(!!user);
    });

    return () => {
      console.log('Cleaning up auth state change listener');
      unsubscribe();
    };
  }, []);

  const handleLoginClick = () => {
    console.log('Navigating to login');
    navigate('/login');
  };

  const handleLogoutClick = async () => {
    try {
      console.log('Signing out');
      await auth.signOut();
      navigate('/'); // Redirect to home after logout
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <HeaderContainer>
      <HeaderLogo onClick={() => navigate('/')} style={{ cursor: 'pointer' }} src="../../logo.png" alt="logo" />
      <FlexDiv>
        <HeaderAbsoluteButton onClick={isUserSignedIn ? handleLogoutClick : handleLoginClick}>
          {isUserSignedIn ? 'Logout' : 'Login'}
        </HeaderAbsoluteButton>
      </FlexDiv>
      {!isUserSignedIn && <i>Sign up to create your very own collection!</i>}
    </HeaderContainer>
  );
}

export default Header;
