// src/components/Login.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import GlobalStyles from '../GlobalStyles';
import { Container, Card, Title, Subtitle, Form, Input, Button, GoogleButton, Text, ErrorMessage } from '../StyledComponents';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  function googleSignin() {
    setLoadingGoogle(true);
    var provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log(token);
        console.log(user);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log(errorCode);
        console.log(errorMessage);
      })
      .finally(() => {
        setLoadingGoogle(false);
      });
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoadingLogin(true);
    setErrorMessage(''); // Clear any previous error messages
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Redirect to the dashboard page
    } catch (error) {
      console.error('Error logging in: ', error);
      setErrorMessage(error.message); // Set the error message to display to the user
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <>
      <GlobalStyles />
      <Container>
        <Card>
          <Title>howwasit?!</Title>
          <Subtitle>Login</Subtitle>
          <Form onSubmit={handleLogin}>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Button type="submit" disabled={loadingLogin}>
              {loadingLogin ? <ClipLoader size={20} /> : 'Login'}
            </Button>
          </Form>
          <GoogleButton onClick={googleSignin} disabled={loadingGoogle}>
            {loadingGoogle ? <ClipLoader size={20} /> : 'Sign in with Google'}
          </GoogleButton>
          <Text>Don't have an account? <Link to="/register">Register</Link></Text>
        </Card>
      </Container>
    </>
  );
}

export default Login;
