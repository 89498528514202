// src/components/CodeInput.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from '../StyledComponents';

const CodeInput = () => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const handleCodeInput = (event) => {
    event.preventDefault();
    if (code.trim() !== '') {
      navigate(`/collection/${code.trim()}`);
    }
  };

  return (
    <Form onSubmit={handleCodeInput}>
      <Input
        type="text"
        placeholder="Enter code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default CodeInput;
