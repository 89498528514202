// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyA0yYyONgHcaTiv-8Dun9UpJO4Qqw6kkw4",
    authDomain: "howwasit-300c0.firebaseapp.com",
    databaseURL: "https://howwasit-300c0-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "howwasit-300c0",
    storageBucket: "howwasit-300c0.appspot.com",
    messagingSenderId: "938145532213",
    appId: "1:938145532213:web:413e1f8098eae988e2247c",
    measurementId: "G-7YMYPHJ8EZ"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
