import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Login from './components/Login';
import Register from './components/Register';
import CreateCollection from './components/CreateCollection';
import UploadPhoto from './components/UploadPhoto';
import Dashboard from './components/Dashboard';
import Header from './components/Header';
import HomePage from './components/HomePage';
import NotFound from './components/NotFound'; // Import the NotFound component
import CollectionPhotos from './components/CollectionPhotos';
import Footer from './components/Footer';
import { AlertProvider } from './components/AlertContext';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import { SpinnerWrapper, Spinner } from './StyledComponents';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false once we know the auth state
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <SpinnerWrapper><Spinner></Spinner></SpinnerWrapper>; // You can replace this with a loading spinner if you have one
  }

  return (
    <AlertProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/create-collection"
            element={
              <ProtectedRoute user={user}>
                <CreateCollection />
              </ProtectedRoute>
            }
          />
          <Route path="/collection/:collectionId" element={<UploadPhoto />}   />
          <Route
            path="/collection/:collectionId/photos"
            element={
              <ProtectedRoute user={user}>
                <CollectionPhotos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute user={user}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} /> {/* Catch all route for 404 */}
        </Routes>
        <Footer />
      </Router>
    </AlertProvider>
  );
}

export default App;
