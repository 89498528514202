// src/components/NotFound.js
import React from 'react';
import { PageContainer, Button } from '../StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const handleBackClick = () => {
    window.history.go(-2);
  };
  const location = useLocation();
  const message = location.state?.message || "Page not found";

  return (
    <PageContainer>
      <h1>{message}</h1>
      <Button onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft}/> Back</Button>
      <p>The collection you are looking for does not exist.</p>
    </PageContainer>
  );
};

export default NotFound;
