// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, setDoc, getDoc, doc, query, where, getDocs } from 'firebase/firestore';
import CollectionsList from './CollectionsList';
import CreateCollection from './CreateCollection';
import SelectedCollection from './SelectedCollection';
import styled from 'styled-components';

const DashboardContainer = styled.div`
  display: flex;
  min-height: 70vh;
`;

const Sidebar = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #f0f2f5;
  height: 300px;
  border-radius: 0px 0px 20px 0px
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
`;

const WelcomeText = styled.p`
  font-size: 18px;
`;

const SectionButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &.active {
    background-color: white;
    color: black;
    text-decoration: underline;
    transition: background-color 0.3s;
  }
  &:hover {
    background-color: #gray;
  }
`;

function Dashboard() {
  const [collections, setCollections] = useState([]);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [customLink, setCustomLink] = useState('');
  const [customLinkError, setCustomLinkError] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [debouncedCustomLink, setDebouncedCustomLink] = useState(customLink);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');
  const [activeSection, setActiveSection] = useState('collections');
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      setDisplayName(auth.currentUser.displayName || 'User');
      fetchCollections();
    } else {
      setError('User not authenticated');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedCustomLink(customLink);
    }, 300);

    return () => {
      clearTimeout(timerId);
    };
  }, [customLink]);

  useEffect(() => {
    if (debouncedCustomLink) {
      checkCustomLinkAvailability();
    } else {
      setCustomLinkError('');
    }
  }, [debouncedCustomLink]);

  const checkCustomLinkAvailability = async () => {
    try {
      const docRef = doc(db, 'collections', customLink);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCustomLinkError('This custom link is already in use. Please choose a different one.');
      } else {
        setCustomLinkError('');
      }
    } catch (error) {
      console.error('Error checking custom link availability: ', error);
      setCustomLinkError('Failed to check custom link availability.');
    }
  };

  const fetchCollections = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'collections'), where('createdBy', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      const fetchedCollections = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCollections(fetchedCollections);
    } catch (error) {
      console.error('Error fetching collections: ', error);
      setError('Failed to fetch collections.');
    } finally {
      setLoading(false);
    }
  };

  const handleCollectionClick = (collection) => {
    setSelectedCollection(collection);
    setSelectedCollectionId(collection.id);
  };

  const handleCreateCollection = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newCollection = {
        name: newCollectionName,
        createdBy: auth.currentUser.uid,
        createdAt: new Date().getTime(),
        expiresAt: expiryDate.getTime(),
        size: 0,
        visibility: visibility,
      };

      let docRef;
      if (customLink) {
        docRef = doc(db, 'collections', customLink);
        await setDoc(docRef, newCollection);
      } else {
        docRef = doc(collection(db, 'collections'));
        await setDoc(docRef, newCollection);
      }

      setCollections(prevCollections => [...prevCollections, { id: docRef.id, ...newCollection }]);
      setNewCollectionName('');
      fetchCollections();
    } catch (error) {
      console.error('Error creating collection: ', error);
      setError('Failed to create collection.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/collection/${selectedCollection.id}`;
    navigator.clipboard.writeText(link).then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('Copy Link'), 1000);
    }, (err) => {
      console.error('Failed to copy link: ', err);
    });
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'collections':
        return (
          <>
            <CollectionsList
              collections={collections}
              handleCollectionClick={handleCollectionClick}
              loading={loading}
              error={error}
              selectedCollectionId={selectedCollectionId}
            />
            <SelectedCollection
              selectedCollection={selectedCollection}
              handleCopyLink={handleCopyLink}
              copyButtonText={copyButtonText}
              selectedCollectionId={selectedCollectionId}
            />
          </>
        );
      case 'create':
        return (
          <CreateCollection
            newCollectionName={newCollectionName}
            setNewCollectionName={setNewCollectionName}
            customLink={customLink}
            setCustomLink={setCustomLink}
            customLinkError={customLinkError}
            expiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            visibility={visibility}
            setVisibility={setVisibility}
            handleCreateCollection={handleCreateCollection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <h1>Dashboard</h1>
        <WelcomeText>Hi {displayName}!</WelcomeText>
        <SectionButton
          className={activeSection === 'collections' ? 'active' : ''}
          onClick={() => setActiveSection('collections')}
        >
          Collections
        </SectionButton>
        <SectionButton
          className={activeSection === 'create' ? 'active' : ''}
          onClick={() => setActiveSection('create')}
        >
          Create Collection
        </SectionButton>
      </Sidebar>
      <MainContent>
        {renderSection()}
      </MainContent>
    </DashboardContainer>
  );
}

export default Dashboard;
