import { FlexDiv, FooterContainer } from '../StyledComponents';

function Header() {
  return (
    <FooterContainer>
          <FlexDiv>
              <div>Solo holiday project</div>
              <div>Contact</div>
      </FlexDiv>
    </FooterContainer>
  );
}

export default Header;
