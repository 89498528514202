import React, { createContext, useState, useContext, useCallback } from 'react';
import { CustomAlert } from '../StyledComponents';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: '', visible: false });

  const showAlert = useCallback((message) => {
    setAlert({ message, visible: true });
  }, []);

  const hideAlert = useCallback(() => {
    setAlert({ message: '', visible: false });
  }, []);

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert.visible && <CustomAlert message={alert.message} onClose={hideAlert} />}
    </AlertContext.Provider>
  );
};
