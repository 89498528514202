// src/StyledComponents.js
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

export const Card = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

export const Subtitle = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  color: #555;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:active {
    background-color: #333;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const GoogleButton = styled(Button)`
  background-color: #db4437;
`;

export const Text = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: #333;
`;

export const HeaderContainer = styled.header`
  padding: 0 30px 0 30px;
  background-color: #f0f2f5;
  text-align: center;
  border-radius: 0 0 20px 20px;
  h1 {
    margin: 0;
    font-size: 2.5em;
  }
  h2 {
    margin: 0.5em 0;
    font-size: 1.5em;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 10px;
`;

export const FadeOutButton = styled(Button)`
  animation: fadeOut 0.3s forwards;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const CollectionContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;

  & button {
    margin-bottom: 10px;
  }
`;

export const CollectionPhotosContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  min-height: 70vh;

  & button {
    margin: 10px;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SidebarContainer = styled.div`
  width: 250px;
  position: fixed;
  top: 0;
  left: ${props => (props.isOpen ? '0' : '-250px')};
  height: 100%;
  background-color: #333;
  color: white;
  transition: left 0.3s;
  padding: 10px;
`;

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
`;

export const ToggleButton = styled(Button)`
  background-color: #black;
  color: white;
  &:hover {
    background-color: #white;
    color: #black;
  }
`;

export const FileInput = styled.input`
  margin-top: 10px;

  &::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  &::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  &::file-selector-button:active {
    background-color: #e5e7eb;
  }
`;

export const ProgressBar = styled.progress`
  width: 100%;
  margin-top: 5px;
  appearance: none;
  border: none;
  height: 10px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ccc;

  &::-webkit-progress-bar {
    background-color: white;
    border-radius: 4px;
  }

  &::-webkit-progress-value {
    background-color: black;
    border-radius: 4px;
    transition: width 0.3s;
  }

  &[value="100"]::-webkit-progress-value {
    background-color: green;
  }
`;

export const ImgPreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover; // works
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 10px;

`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const PageContainer = styled.div`
`;

export const SettingsButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: #007bff;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  z-index: 1001;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.h2`
  margin: 0;
`;

export const ModalCloseButton = styled(FontAwesomeIcon)`
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  margin-bottom: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const RadioInput = styled.input`
  margin-right: 5px;
`;

export const ListContainer = styled.div`
  text-align: center;
`;

export const CollectionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const CollectionItem = styled.li`
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f2f5;
  border-radius: 4px;
  &:hover {
    background-color: #e0e4e8;
  }
  &.selected {
    background-color: #d0d4d8;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 16px solid black;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ImageGrid = styled.div`
  display: grid;
  object-fit: cover;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 20px;
  background-color: #f0f2f5;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  background:
    repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #ccc 10px,
    #ccc 20px
  );
`;

export const CenteredColumnDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  & h3 {
    margin-bottom: 0px;
  }

  & p {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  & button {
    margin-bottom: 10px;
`;

export const HeaderLogo = styled.img`
  cursor: pointer;
  height: 75px;
`;

export const HeaderAbsoluteButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: black;
`;

export const BlackContainer = styled.div`
  background-color: black;
  color: white;
  padding: 20px;
  margin-top: 20px;
`;

export const WhiteContainer = styled.div`
  background-color: white;
  color: black;
  padding: 20px;
  margin-top: 20px;

  & h1 {
    margin-bottom: 10px;
    margin-top: 0px;
  }
`;

export const FooterContainer = styled.footer`
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  min-height: 200px;
`;

export const ButtonDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  width: 100px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 20px;

  & h3 {
    margin: 0;
    margin-bottom: 5px;
  }
`;
export const Container5050 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Black50Container = styled.div`
  width: 50%;
  background-color: black;
  color: white;
  padding: 20px;
  margin-top: 20px;
`;

export const White50Container = styled.div`
  width: 50%;
  background-color: white;
  color: black;
  padding: 20px;
  margin-top: 20px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  &:checked {
    color: white;
    background-color: red;
    border: 2px solid black;
    position: relative;
  }

`;

export const BlueButton = styled(Button)`
  background-color: lightblue;
  color: black;
`;

const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const AlertBox = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const AlertMessage = styled.p`
  margin: 0 0 20px;
`;

const AlertButton = styled.button`
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
`;

export const CustomAlert = ({ message, onClose }) => {
  return (
    <AlertOverlay>
      <AlertBox>
        <AlertMessage>{message}</AlertMessage>
        <AlertButton onClick={onClose}>OK</AlertButton>
      </AlertBox>
    </AlertOverlay>
  );
};

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    position: relative;
    max-width: 90%;
    max-height: 90%;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px;
    background-color: black;
    border-radius: 10px;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export const ImagePreview = styled.img`
  max-width: 100vw !important;
  max-height: 90vh !important;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid black;
`;