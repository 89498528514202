// src/components/SelectedCollection.js
import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { faCog, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CollectionContainer, QRCodeContainer, Button, SettingsButton, CenteredColumnDiv, FlexDiv } from '../StyledComponents';
import CollectionSettingsModal from './CollectionSettingsModal';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

function SelectedCollection({ selectedCollectionId, handleCopyLink, copyButtonText }) {
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!selectedCollectionId) {
            setSelectedCollection(null); // Clear selectedCollection if no ID is provided
            return;
        }

        const collectionRef = doc(db, 'collections', selectedCollectionId);
        const unsubscribe = onSnapshot(collectionRef, (doc) => {
            if (doc.exists()) {
                setSelectedCollection({
                    id: doc.id,
                    ...doc.data(),
                });
            } else {
                setSelectedCollection(null); // Handle case where collection is deleted
            }
        });

        return () => unsubscribe();
    }, [selectedCollectionId]);

    const handleNavigateToPhotos = () => {
        navigate(`/collection/${selectedCollection.id}/photos`, {
            state: { selectedCollectionId: selectedCollection.id }
        });
    };

    const handleSettingsClick = () => {
        setShowSettingsModal(true);
    };

    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false);
    };

    return (
        <CollectionContainer>
            {selectedCollection ? (
                <FlexDiv>
                    <div>
                        <h3 style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedCollection.name}
                            <SettingsButton icon={faCog} onClick={handleSettingsClick} />
                        </h3>
                        <p>Created At: {new Date(selectedCollection.createdAt).toLocaleString()}</p>
                        <p>Expires At: {new Date(selectedCollection.expiresAt).toLocaleString()}</p>
                        <p>Size: {selectedCollection.size}</p>
                        <p style={{ color: selectedCollection.visibility ? 'black' : 'red' }}>
                            Visibility: {selectedCollection.visibility ? 'Public' : 'Private'}
                        </p>
                        <Button onClick={handleNavigateToPhotos}>View Photos</Button>
                    </div>
                    <CenteredColumnDiv>
                        <b>Share me!</b>
                        <Button onClick={handleCopyLink}>{copyButtonText}</Button>
                        <QRCodeContainer>
                            <QRCodeCanvas value={`${window.location.origin}/collection/${selectedCollection.id}`} />
                        </QRCodeContainer>
                    </CenteredColumnDiv>
                    <CollectionSettingsModal
                        show={showSettingsModal}
                        handleClose={handleCloseSettingsModal}
                        selectedCollection={selectedCollection}
                    />
                </FlexDiv>
            ) : (
                <p>Click on a collection to display details here!</p>
            )}
        </CollectionContainer>
    );
}

export default SelectedCollection;
