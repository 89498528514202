// src/components/HomePage.js
import React from 'react';
import CodeInput from './CodeInput';
import { Button, CenteredColumnDiv, BlackContainer, PageContainer, WhiteContainer, ButtonDiv } from '../StyledComponents';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/HomePage.css';

function HomePage() {

  const navigate = useNavigate();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(() => {
    console.log('Setting up auth state change listener');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user);
      setIsUserSignedIn(!!user);
    });

    return () => {
      console.log('Cleaning up auth state change listener');
      unsubscribe();
    };
  }, []);

  return (
    <PageContainer>
      {isUserSignedIn &&
        <CenteredColumnDiv>
          <h3>Welcome back &lt;3</h3>
          <p>Is this what you're looking for?</p>
          <Button onClick={() => navigate('/dashboard')}>Dashboard</Button>
        </CenteredColumnDiv>}
        <BlackContainer>
          <h1>Remember that?</h1>
          <p>Platform made for easy photo sharing. Whether it is a festival, birthday party or a wedding, we have you covered!</p>
        </BlackContainer>
        <WhiteContainer>
          <h1>Have a code?</h1>
          <ButtonDiv>
            <h3>Put it here!</h3>
            <CodeInput />
          </ButtonDiv>
        </WhiteContainer>
    </PageContainer>
  );
}

export default HomePage;
